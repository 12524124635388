import { Component } from "@angular/core";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { NavigationItem } from "@rubbl-layout";
import { filter } from "rxjs";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent {
  navigationItems: NavigationItem[] = [
    {
      label: "Orders",
      children: [
        { label: "Active Order Items", route: "/order/orderitems" },
        { label: "Orders", route: "/order/items" },
        { label: "Service", route: "/order/service" },
        { label: "Map", route: "/order/map" },
      ],
    },
    {
      label: "Accounts",
      children: [
        { label: "Accounts", route: "/account" },
        { label: "Pending Tasks", route: "/account/activities" },
        { label: "Carts", route: "/account/carts" },
      ],
    },
    {
      label: "Inventory",
      children: [
        { label: "Machines", route: "/inventory" },
        { label: "Attachments", route: "/inventory/attachments" },
      ],
    },
    { label: "Inspections", route: "/inspection" },
    { label: "Billing", route: "/billing" },
    {
      label: "Dashboard",
      route: "/dashboard",
    },
  ];

  constructor(private router: Router) {}

  addContainerClass: boolean = true;

  ngOnInit() {
    if (window.location.pathname.includes("order/map")) {
      this.addContainerClass = false;
    }

    this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((result: any) => {
      if (result.url.includes("order/map")) {
        this.addContainerClass = false;
      } else {
        this.addContainerClass = true;
      }
    });
  }
}
