import { AccountMapComponent } from "@rubbl-azure-maps";
import { AuthGuard } from "@rubbl-core";
import { LayoutComponent } from "app/layout/layout.component";

export const routes = [
  {
    path: "map",
    canActivate: [AuthGuard],
    component: AccountMapComponent,
    data: { login: true },
  },
  {
    path: "",
    component: LayoutComponent,
    canActivate: [AuthGuard],
    data: { login: true },
    children: [
      {
        path: "order",
        loadChildren: () => import("./order/order.module").then(m => m.OrderModule),
        data: {
          pageTitle: "Orders",
        },
      },
      {
        path: "account",
        loadChildren: () => import("./account/account.module").then(m => m.AccountModule),
        data: {
          pageTitle: "Account",
        },
      },
      {
        path: "inventory",
        loadChildren: () => import("./inventory/inventory.module").then(m => m.InventoryModule),
        data: {
          pageTitle: "Inventory",
        },
      },

      {
        path: "inspection",
        loadChildren: () => import("./inspection/inspection.module").then(m => m.InspectionModule),
        data: {
          pageTitle: "Inspections",
        },
      },

      {
        path: "billing",
        loadChildren: () => import("./billing/billing.module").then(m => m.BillingModule),
        data: {
          pageTitle: "Billing",
        },
      },
      {
        path: "dashboard",
        loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule),
        data: {
          pageTitle: "Dashboard",
        },
      },
      // must be last
      {
        path: "",
        redirectTo: "order",
        pathMatch: "full",
      },
    ],
  },

  // Not found
  { path: "**", redirectTo: "", canActivate: [AuthGuard], data: { login: true } },
];
